.main-header {
  width: 100%;
  @media (min-width: 992px) {
    height: 100px; }
  @media (max-width: 991px) {
    height: 60px;
    padding-right: 0; }
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: all .3s ease 0s;
  h1 {
    @media (min-width: 992px) {
      width: 210px; }
    @media (max-width: 991px) {
      width: calc( 100% - 60px ); } }

  .wp-block-group__inner-container {
    position: relative;
    width: 100%;
    max-width: 1000px;
    height: 100%;
    margin-left: auto;
    margin-right: auto; } }
.main-logo {
  width: 210px;
  position: relative;
  top: 13px;
  transition: all .3s ease 0s; }
img.main-logo {
  width: 210px; }

.sp-logo {
  width: 100%;
  max-width: 297px;
  position: relative;
  top: 13px; }
img.sp-logo {
  width: 100%; }
.sub-navigation {
  width: 248px;
  position: absolute;
  right: 130px;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: stretch;
  justify-content: flex-end;
  transition: all .3s ease 0s;
  li {
    flex: 0 0 120px;
    margin-left: 2px;
    margin-right: 2px;
    a {
      display: block;
      background: #B4A500;
      color: #fff;
      line-height: 36px;
      font-size: 12px;
      border-radius: 0 0 10px 10px; } } }
.contact-navigation {
  width: 124px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all .3s ease 0s;
  li {
    margin-left: 2px;
    margin-right: 2px; }
  a {
    display: block;
    background: #656565;
    width: 120px;
    line-height: 36px;
    border-radius: 0 0 10px 10px;
    color: #fff;
    font-size: 12px; } }
.global-navigation {
  width: calc( 100% - 220px);
  position: absolute;
  right: 0;
  bottom: 12px;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  justify-content: flex-end;
  a {
    color: #232323; }
  li {
    margin-left: 30px;
    position: relative;
    padding-bottom: 5px;
    &:first-of-type {
      margin-left: 0; }
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      width: 0;
      background: $c1;
      position: absolute;
      left: -3px;
      bottom: 0;
      transition: all .5s ease 0s; }
    &:hover {
      &:after {
        width: calc( 100% + 6px ); } } }
  img {
    height: 18px;
    width: auto !important;
    vertical-align: middle;
    margin-right: 5px; } }

.main-header.scrolled {
  @media (min-width: 992px) {
    height: 60px; }
  .main-logo {
    width: 110px;
    top: 10px; }
  @media (min-width: 992px) {
    h1 {
      width: 110px; } }
  @media (max-width: 991px) {
    h1 {
      max-width: 297px; } }
  .sub-navigation {
    top: -60px; }
  .contact-navigation {
    top: -60px; } }




.spbtn {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
  padding: 12px 14px;
  @media (min-width: 992px) {
    display: none; }
  &:after {
    content: 'MENU';
    font-family: $f1;
    font-size: 10px;
    color: $c1;
    position: absolute;
    left: 0;
    bottom: 6px;
    text-align: center;
    width: 100%; }
  span {
    display: block;
    width: 100%;
    height: 2px;
    background: $c1;
    position: relative;
    transition: all 0.25s ease-out 0s; }
  .l1 {
    top: 0; }
  .l2 {
    top: 8px; }
  .l3 {
    top: 16px; }
  // &.active
  //.l1
  //  left: 0
  //  top: 11px
  //  transform: rotate(45deg)
  //.l2
  //  opacity: 0
  //.l3
  //  left: 0
  //  top: 7px
 }  //  transform: rotate(-45deg)

