article,aside,details,figcaption,figure,footer,header,hgroup,nav,section,summary {
  display: block; }
audio,canvas,video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }
audio:not([controls]) {
  display: none;
  height: 0; }
[hidden] {
  display: none; }
body {
  margin: 0;
  padding: 0; }
a {
  outline: 0;
  text-decoration: none;
  color: #232323; }
p,li,dt,dd,th,td {
  a {
    color: $lc1; } }
li {
  margin: 0;
  padding: 0;
  list-style-position: inside;
  list-style-type: none;
  line-height: 150%; }
dl,menu,ol,ul {
  margin: 0;
  padding: 0; }
dt,dd {
  margin: 0;
  padding: 0;
  line-height: 150%; }
nav ul,nav ol {
  list-style: none;
  list-style-image: none; }
img {
  border: 0; }
svg:not(:root) {
  overflow: hidden; }
figure {
  margin: 0;
  padding: 0; }
form {
  margin: 0;
  padding: 0; }
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0;
  padding: 0;
  padding: 0.35em 0.625em 0.75em; }
legend {
  border: 0;
  white-space: normal;
  padding: 0; }
button,input,select,textarea {
  padding: 0; }
button,input {
  line-height: normal; }
button,html input[type="button"] {
  -webkit-appearance: button; }
button[disabled],input[disabled] {
  cursor: default; }
input[type="checkbox"],input[type="radio"] {
  box-sizing: border-box; }
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box; }
input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }
button::-moz-focus-inner,input::-moz-focus-inner {
  border: 0;
  padding: 0; }
textarea {
  overflow: auto; }
table {
  border-collapse: collapse;
  border-spacing: 0; }
th,td {
  line-height: 150%; }
body {
  text-align: center;
  color:#333 {} }
h1,h2,h3,h4,h5,h6,p,li,th,td,dt,dd,pre,code,input,textarea,button,select,option,blockquote,q,figcaption,a {
  font-family: $f2;
  font-feature-settings: palt;
  box-sizing: border-box; }
div,section,header,footer,nav,figure,ul,dl,table {
  box-sizing: border-box; }
select {
  width: 100%;
  padding: 10px;
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
  border: 1px solid #ccc;
  background: #E7E7E7;
  border-radius: 10px; }

body {
  &>* {
    position: relative;
    z-index: 5; } }



img[width] {
  width: 100%;
  height: auto; }

h1,h2,h3,h4,h5,h6,p,li,th,td,dt,dd,pre,code,input,textarea,button,select,option,blockquote,q,figcaption,a {
  margin: 0;
  padding: 0; }
h1,h2,h3,h4,h5,h6,p,li,th,td,dt,dd,pre,code,input,textarea,button,select,option,blockquote,q,figcaption {
  font-size: 14px;
  line-height: 200%; }
hr {
 border: none;
 height: 1px;
 background: #e9e9e9;
 max-width: 1000px;
 margin-top: 30px;
 margin-bottom: 30px; }

body {
  &>h1,h2,h3,h4,h5,h6,p {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto; }
  &>.wp-block-image {
    img {
      max-width: 1000px;
      width: 100%;
      height: auto; } } }
