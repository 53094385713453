.wp-block-group {
  position: relative;
  .wp-block-group__inner-container {
    position: relative; } }


.wp-block-cover {
  position: relative;
  .wp-block-cover__inner-container {
    position: relative;
    width: 100%; } }



.wp-block-image {
  position: relative;
  a.link {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; } }
.wp-block-columns {
  margin: 0;
  padding: 0;
  @media (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px; }
  .wp-block-column {
    margin: 0;
    padding: 0; } }


@media (max-width: 991px) {
  .wp-block-group {
    .wp-block-columns {
      padding-left: 0;
      padding-right: 0; } } }



.wp-block-buttons {
  .wp-block-button {
    @media (min-width: 992px) {
      min-width: 320px; }
    @media (max-width: 991px) {
      max-width: 320px;
      &:only-of-type {
        width: 100%; } }
    .wp-block-button__link {
      width: 100%; } } }
.btn-right-arrow {
  .wp-block-button__link {
    position: relative;
    &::after {
      @include  fw;
      position: absolute;
      right: 30px;
      content: "\f054";
      top: 21px;
      font-size: 10px;
      line-height: 100%; } } }

.wp-block-columns {
  margin-bottom: 100px; }


body {
 @media (max-width: 991px) {
   &>* {
     padding-left: 20px;
     padding-right: 20px; } } }


