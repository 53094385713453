a#top {
  display: block;
  width: 1px;
  height: 0; }
.hd2-2 {
  @include header2-2; }
.hd2-3 {
  @include header2-2;
  @include header-decoration1; }
.hd2-3-caption {
    font-size: 14px;
    margin-bottom: 40px;
    @media (min-width: 768px) {
      line-height: 100%; }
    @media (max-width: 767px) {
      line-height: 180%; } }

.hd2-4 {
  @include header2-2;
  @include header-decoration2; }
.hd2-5 {
  background: $c4;
  border-top: 1px solid $c1;
  border-bottom: 1px solid $c1;
  margin-bottom: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 16px; }

.home-main-visual {
  position: relative;
  z-index: 10;
  @media (max-width: 767px) {
    height: 300px;
    min-height: 300px;
    background: url(../image/homesp.jpg) no-repeat center / cover !important; }
  .wp-block-cover__inner-container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 1100px) {
      display: flex;
      flex-direction: row;
      flex-flow: wrap;
      align-items: center;
      justify-content: space-between;
      height: 688px; }
    @media (max-width: 1099px) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: 100%; }
    @media (max-width: 767px) {
      height: 300px;
      min-height: 300px; } } }
.home-main-visual-title {
  width: 100%;
  @media (min-width: 1100px) {
    max-width: 479px; }
  @media (max-width: 1099px) {
    width: 80%;
    max-width: 400px;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto; }
  img {
    @media (min-width: 1100px) {
      position: relative;
      left: -45px; } }
  // @media (max-width: 1099px)
  //width: 130px
  //height: 130px
  //right: 20px
  //bottom: 30px
  @media (max-width: 667px) {
    width: 100%;
    height: 130px;
    margin-top: 60px; } }



.home-main-visual-qa-banner {
  position: absolute;
  @media (min-width: 1100px) {
    width: 171px;
    height: 171px;
    right: -100px;
    bottom: 30px; }
  @media (max-width: 1099px) {
    width: 130px;
    height: 130px;
    right: 20px;
    bottom: 0px; }
  @media (max-width: 667px) {
    width: 130px;
    height: 130px;
    right: 10px;
    bottom: -40px; } }



.home-news {
  .wp-block-group__inner-container {
    max-width: 1000px; }
  background: #F4F1D9;
  width: 100%;
  padding: 10px 0 18px;
  z-index: 5;
  margin-bottom: 70px;
  @media (max-width: 991px) {
    padding-bottom: 40px; }
  .wp-block-group__inner-container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    line-height: 100%;
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;
    align-items: center;
    justify-content: flex-start;
    position: relative; }
  h2 {
    font-family: $f1;
    font-weight: 500; }

  .news-wrapper {
    border-left: 1px solid $c1;
    width: calc( 100% - 210px );
    margin-left: -1px;
    @media (min-width: 992px) {
      padding-left: 40px; }
    @media (max-width: 991px) {
      padding-left: 10px;
      width: calc( 100% - 120px ); } }
  p,li {
    margin: 0;
    padding: 0; }
  p {
    a {
      color: $c1;
      &:after {
        @include fw;
        content:  "\f054";
        font-size: 12px;
        margin-left: 10px;
        position: relative;
        top: -1px;
        display: inline-block; } }
    @media (min-width: 668px) {
      padding-right: 10px;
      width: 120px; }
    @media (max-width: 667px) {
      position: absolute;
      right: 15px;
      bottom: -33px; } }
  ul {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: center;
    flex: 1 1 auto;
    margin-bottom: 10px;
    // @media (min-width: 768px)
    @media (max-width: 667px) {
      position: relative;
      align-items: flex-start; }
    li {
      line-height: 130%; }
    li.new {
      width: 50px;
      margin-right: 20px;
      .the-new {
        background: red;
        color: #fff;
        display: block;
        width: 100%;
        font-size: 12px; }
      @media (max-width: 480px) {
        &:empty {
          display: none; } } }
    li.title {
      @media (max-width: 480px) {
        padding-right: 10px;
        text-align: left;
        width: calc( 100% - 70px ); } }
    li.date {
      color: $c1;
      margin-right: 20px;
      @media (max-width: 480px) {
        width: 100%;
        text-align: left;
        margin-bottom: 6px; } }
    a {
      color: $c2;
      text-decoration: none; } }
  h2 {
    border-right: 1px solid $c1;
    color: $c1;
    font-weight: bold;
    line-height: 60px;
    margin: 0;
    @media (min-width: 1100px) {
      width: 170px;
      font-size: 30px; }
    @media (max-width: 1099px) {
      width: 100px;
      font-size: 28px; } } }


.hd2-1 {
  font-size: 30px;
  line-height: 130%;
  color: $c2;
  @media (max-width: 991px) {
    font-size: 24px; }
  margin-bottom: 5px;
  &::before {
    content: "";
    width: 90px;
    height: 22px;
    display: block;
    background: url( ../image/img34.png) no-repeat center / contain;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px; } }
.hd2-1-caption {
    color: $c3;
    font-size: 14px;
    line-height: 130%;
    margin-bottom: 40px;
    font-family: $f1;
    font-weight: 500; }

.search-by-area {}


.area-list {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  text-align: left;
  flex-flow: wrap;
  justify-content: center;
  @media (max-width: 991px) {
    padding-left: 0;
    padding-right: 0; }
  @media (max-width: 770px) {
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .wp-block-column {
    flex: 0 1 auto;
    margin-bottom: 40px;
    &:first-of-type {
      margin-left: 0; }
    @media (min-width: 1100px) {
      margin-left: 56px;
      width: calc( 33.333% - 56px);
      &:nth-of-type(3n+1) {
        margin-left: 0; } }
    @media (max-width: 1099px) {
      width: calc( 50% - 40px);
      margin-bottom: 40px;
      margin-left: 40px;
      &:nth-of-type(2n+1) {
        margin-left: 0; } }
    @media (max-width: 770px) {
      width: 100%;
      margin-left: auto !important;
      margin-right: auto !important; } }

  h3 {
    border-top: 2px solid $c1;
    border-bottom: 2px solid $c1;
    background: $c4;
    line-height: 50px;
    position: relative;
    margin-bottom: 15px;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 55px;
      height: 58px;
      left: 21px;
      top: -14px; } }
  .kanto {
    &::before {
      background: url( ../image/img04.png) no-repeat center /contain; } }
  .chubu {
    &::before {
      background: url( ../image/img05.png) no-repeat center /contain; } }
  .kansai {
    &::before {
      background: url( ../image/img06.png) no-repeat center /contain; } }
  .tohoku-hokkaido {
    &::before {
      background: url( ../image/img70.png) no-repeat center /contain; } }
  .kyushu-okinawa {
    &::before {
      background: url( ../image/img71.png) no-repeat center /contain; } }

  li {
    margin-bottom: 7px; }
  a {
    @include area-list; } }


.search-by-type {
  margin-bottom: 100px;
  @media (max-width: 991px) {
    margin-bottom: 50px; } }


.type-list {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  flex-flow: wrap;
  justify-content: center;


  .wp-block-column {
    margin-left: 20px;
    &:first-of-type {
      margin-left: 0; }
    @media (max-width: 1099px) {
      flex: 0 0 30% !important; }
    @media (max-width: 770px) {
      flex: 0 0 45% !important;
      &:nth-of-type(2n+1) {
        margin-left: 0; } } }
  figure {
    img {
      border-radius: 20px;
      overflow: hidden; } }
  figcaption {
    font-size: 15px;
    border-bottom: 2px solid $c1;
    display: inline-block  !important;
    font-weight: bold; }
  & + .wp-block-buttons {
    margin-bottom: 100px; } }

.guide {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  // @media (min-width: 1100px)
  @media (max-width: 1099px) {
    justify-content: center; }
  .wp-block-column {
    margin-left: 20px;
    border: 1px solid $c1;
    border-radius: 20px;
    &:first-of-type {
      margin-left: 0; }
    @media (max-width: 768px) {
      margin-bottom: 20px;
      &:nth-of-type(2n+1) {
        margin-left: 0; } }
    @media (max-width: 599px) {
      margin-left: 0; } }

  .wp-block-image {
    margin-bottom: 0;
    padding: 25px 15px;
    // @media (min-width: 768px)
    @media (max-width: 767px) {
      padding: 15px; } }
  figure {
    width: 100%;
    @media (min-width: 768px) {
      padding-bottom: 15px; }
    @media (max-width: 767px) {
      display: block !important;
      a {
        display: block !important; } }
    img {
      height: 40px;
      width: auto;
      @media (max-width: 767px) {
        margin-bottom: 10px; } }
    figcaption {
      border-top: 1px solid #e9e9e9;
      margin-bottom: 0;
      padding-top: 10px;
      font-size: 16px;
      line-height: 150%;
      // @media (min-width: 768px)
      @media (max-width: 767px) {
        margin-top: 0;
        display: block !important;
        br {
          display: none; } } } } }

div.blog {
  background: $c4 url( ../image/img10.png) repeat center top / contain;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 100px;
  position: relative;
  @media (max-width: 991px) {
    padding-top: 40px; }
  &:before {
    content: '';
    display: block;
    position: absolute;
    background: rgba( #fff, .4);
    @media (min-width: 992px) {
      top: 0px;
      width: 100%;
      max-width: 350px;
      height: 175px;
      border-radius: 0 0 175px 175px;
      left: calc( 50% - 175px ); }
    @media (max-width: 991px) {
      top: 0px;
      width: 90vw;
      height: 45vw;
      border-radius: 0 0 45vw 45vw;
      left: 5vw; } } }

.reccommend-vision {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: stretch;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 5;
  .wp-block-group__inner-container {
    width: 100%; }
  .wp-block-columns {
    @media (min-width: 1100px) {
      margin-bottom: 30px; }
    @media (max-width: 1099px) {
      margin-bottom: 0px; }

    @media (max-width: 1099px) {
      flex-flow: wrap;
      justify-content: center; } }

  .wp-block-column {
    margin-left: 20px;
    border-radius: 10px;
    border: 2px solid $c3;
    overflow: hidden;
    position: relative;
    background: #fff;
    &:first-of-type {
      margin-left: 0; }
    @media (min-width: 1100px) {
      max-width: 240px; }
    @media (max-width: 1099px) {
      flex: 0 1 46%;
      margin-bottom: 20px;
      &:nth-of-type(2n+1) {
        margin-left: 0; } }
    @media (max-width: 599px) {
      margin-left: 0; } }
  .wp-block-column:empty {
    border: none;
    background: none; }
  .wp-block-buttons {
    height: 0;
    a.wp-block-button__link {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      top: 0;
      left: 0;
      padding: 0;
      min-width: 1px;
      opacity: 0; } }
  .wp-block-image {
    position: relative;
    height: 80px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    figure {}
    img {
      max-height: 60px;
      width: auto;
      max-width: calc( 100% - 30px ); } }
  h3 {
    background: $c3;
    color: #fff;
    margin: 0;
    font-size: 13px;
    line-height: 40px; } }

.lower-header {
  box-sizing: border-box;
  @media (min-width: 1100px) {
    margin-top: 100px;
    margin-bottom: 80px;
    min-height: 200px; }
  @media (max-width: 1099px) {
    background: url( ../image/img73.jpg ) no-repeat center bottom / cover !important;
    margin-top: 60px;
    margin-bottom: 25px;
    min-height: 140px; }
  &:before {
    display: none; } }

.h1-1 {
  line-height: 140%;
  @media (min-width: 992px) {
    margin-bottom: 5px;
    font-size: 30px; }
  @media (max-width: 991px) {
    font-size: 24px; }
  &:before {
    content: '';
    background: url( ../image/img39.png ) no-repeat center / contain;
    width: 90px;
    height: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 992px) {
      margin-bottom: 10px; }
    @media (max-width: 991px) {
      margin-bottom: 5px; } } }
.h1-1-caption {
  font-family: $f1;
  font-weight: 500;
  @media (min-width: 992px) {
    font-size: 14px; }
  @media (max-width: 991px) {
    font-size: 11px; } }

.corporate1 {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-bottom: 80px;
  h2 {}
  .wp-block-media-text__content {
    @media (max-width: 1099px) {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  .wp-block-table {
    display: table;
    overflow: auto;
    white-space: normal; }
  table {
    margin-bottom: 30px; }
  td,th {
    border-bottom: 1px solid #e9e9e9;
    vertical-align: top;
    padding: 5px; }
  tr {
    td {
      &:first-of-type {
        font-weight: bold;
        padding-right: 15px;
        min-width: 90px;
        &:before {
          content: '\25CF';
          color: $c1; } } } }
  .wp-block-media-text {
    @media (max-width: 991px) {
      display: block;
      .wp-block-media-text__media {}
      .wp-block-media-text__content {
        padding: 20px 0; } } } }

.single-vproduct {
  .corporate1 {
    padding-top: 40px; } }

.googlemap {
  width: 100%;
  max-width: 2000px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1099px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0; } }


.qalist {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  li {
    position: relative;
    overflow: hidden;
    transition: all .3s ease 0s;
    &:first-of-type {
      background: $c1;
      color: #fff;
      display: flex;
      flex-direction: row;
      flex-flow: wrap;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      border-radius: 30px;
      cursor: pointer;
      line-height: 150%;
      @media (min-width: 1100px) {
        height: 60px;
        font-size: 20px; }
      @media (max-width: 1099px) {
        min-height: 60px;
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 16px; }
      &:before {
        content: "Q";
        position: absolute;
        color: $c3;
        font-family: $f1;
        font-weight: 500;
        @media (min-width: 1100px) {
          font-size: 36px;
          left: 80px; }
        @media (max-width: 1099px) {
          font-size: 30px;
          left: 20px; } }
      &:after {
        @include fw;
        content: "\f078";
        position: absolute;
        @media (min-width: 1100px) {
          right: 40px; }
        @media (max-width: 1099px) {
          right: 20px; } } }
    &:not( :first-child ) {
      display: flex;
      align-items: center;
      text-align: left;
      margin-top: 0;
      max-height: 0;
      background: #fff;
      @media (min-width: 1100px) {
        padding-left: 180px;
        padding-right: 80px; }
      @media (max-width: 1099px) {
        padding-left:  40px;
        padding-right: 20px; }
      &:before {
        @include fw;
        content: "A";
        font-size: 36px;
        position: relative;
        color: $c1;
        font-family: $f1;
        font-weight: 500;
        @media (min-width: 1100px) {
          left: -97px; }
        @media (max-width: 1099px) {
          left: -20px; } } } }
  &:last-of-type {
    margin-bottom: 100px; }
  &.active {
    li {
      &:not( :first-of-type) {
        margin-top: 20px;
        max-height: 200px;
        overflow-y: auto;
        @media (max-width: 991px) {
          max-height: 500px; } } } }
  // &.inactive
  //li
  //  &:not( :first-of-type)
 }  //    height: 0px


.alert1 {
  background: #D9EBD9;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: stretch;
  justify-content: center;
  color: $c5;
  margin-bottom: 40px;
  position: relative;
  @media (max-width: 991px) {
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 70px; }
  &:before {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    background: url( ../image/img40.png ) no-repeat center / contain;
    margin-right: 20px;
    @media (max-width: 991px) {
      margin-bottom: 20px;
      position: absolute;
      left: 20px;
      top: 25px; } } }



.area-map {
  margin-top: 80px;
  margin-bottom: 150px;
  .wp-block-group__inner-container {
    max-width: 790px;
    margin-left: auto;
    margin-right: auto;
    position: relative; }
  h3 {
    display: none; }
  ul {
    text-align: left; }
  a {
    @include area-list; }
  .kansai-list {
    position: absolute;
    top: 430px;
    left: 148px; }

  .chubu-list {
    position: absolute;
    top: 120px;
    left: 0; }
  .kanto-list {
    position: absolute;
    top: 260px;
    left: 497px; } }

.mw_wp_form_input {
  margin-top: 40px;
  margin-bottom: 100px;
  ul {
    background: #F7F6E5;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    &:first-of-type {
      border-radius: 20px 20px 0 0; }
    &:last-of-type {
      border-radius:  0 0 20px 20px; }
    @media (min-width: 1100px) {
      padding: 10px 50px;
      &:first-of-type {
        padding-top: 50px; }
      &:last-of-type {
        padding-bottom: 50px;
        margin-bottom: 40px; } }
    @media (max-width: 1099px) {
      padding: 10px 20px;
      &:first-of-type {
        padding-top: 30px; }
      &:last-of-type {
        padding-bottom: 30px;
        margin-bottom: 20px; } }
    li {
      @media (min-width: 1100px) {
        &:first-of-type {
          flex: 0 0 200px;
          padding-top: 10px;
          text-align: left; }
        &:last-of-type {
          flex: 1 1 auto;
          width: calc( 100% - 200px); } }
      @media (max-width: 1099px) {
        text-align: left;
        &:first-of-type {
          width: 100%; }
        &:last-of-type {
          width: 100%; } } } }
  input[type=text],
  input[type=email],
  input[type=tel],
  select,
  textarea {
    @include inputbasic1; }
  select {
    @include select1; }
  h2 {
    color: $c5;
    font-size: 18px; }
  h2,p {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto; }

  .agreement {
    border-top: 1px solid #bdbdbd;
    margin-top: 20px;
    padding-top: 20px; }
  p {
    a {
      color: $lc1; }
    label {
      font-size: 18px;
      input {
        vertical-align: middle;
        position: relative;
        top: -1px; } } }
  input[type=submit] {
    margin-top: 40px;
    @include button1; }
  ul {
    &.require {
      li {
        &:first-of-type {
          position: relative;
          &:after {
            content: '\5FC5\9808';
            position: absolute;
            border: 1px solid $c5;
            line-height: 100%;
            padding: 3px;
            color: $c5;
            border-radius: 3px;
            font-size: 12px;
            background-color: #fff;
            @media (min-width: 1100px) {
              right: 20px; }
            @media (max-width: 1099px) {
              right: 0; } } } } } } }

.contact-panel1 {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto; }


.table1 {
  max-width: 720px;
  table {
    margin-bottom: 20px; }
  th,td {
    padding: 10px;
    border: 1px solid #D3D3D3;
    background: #fff; }
  thead {
    th {
      background: $c1;
      color: #fff; } }
  tbody {
    tr {
      &:nth-of-type(odd) {
        th,td {
          background: #E5F1E5; } } } } }



.about1 {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 991px) {
    flex-flow: wrap;
    margin-bottom: 0px; }
  .wp-block-column {
    border: 1px solid $c3;
    border-radius: 15px;
    overflow: hidden;
    margin-left: 60px;
    padding-bottom: 30px;
    &:nth-of-type(1) {
      margin-left: 0; }
    @media (max-width: 991px) {
      flex: 0 0 100% !important;
      margin-left: 0;
      margin-bottom: 30px; } }
  h2 {
    background: url( ../image/img45.jpg ) no-repeat center / cover;
    color: #fff;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $c3;
    @media (min-width: 1100px) {
      font-size: 24px; }
    @media (max-width: 1099px) {
      font-size: 22px; } }
  p {
    padding-left: 30px;
    padding-right: 30px; }
  h2 + p {
    background-color: #D9EBD9;
    height: 50px;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (min-width: 1100px) {
      font-size: 16px; }
    @media (max-width: 1099px) {
      font-size: 13px; }
    &:after {
      display: block;
      content: '';
      width: 30px;
      height: 24px;
      background: url( ../image/img46.png) no-repeat center / contain;
      position: absolute;
      left: calc( ( 100% - 15px ) / 2 );
      bottom: -24px; } }
  .wp-block-image {
    background: url( ../image/img47.png ) no-repeat center top 40px / 280px auto;
    @media (min-width: 992px) {
      padding-top: 80px;
      padding-bottom: 40px;
      padding-left: 50px;
      padding-right: 50px; }
    @media (max-width: 991px) {
      padding-top: 65px;
      padding-bottom: 0px;
      padding-left: 20px;
      padding-right: 20px;
      background: url( ../image/img47.png ) no-repeat center  40px / 200px auto;
      img {
        max-width: 300px; } } }
  .wp-block-buttons {
    margin-top: 20px;
    .wp-block-button {
      @media (max-width: 1099px) {
        width: 100%; }
      .wp-block-button__link {
        @include button1(calc(100% - 40px),280px); } } } }

.type-name {
  min-height: 100px !important;
  &:after {
    content: '';
    display: block;
    width: 30px;
    height: 23px;
    min-height: 0px;
    position: absolute;
    background: url( ../image/img50.png ) no-repeat center / contain;
    left: calc( ( 100% - 30px ) / 2 );
    bottom: -23px; }
  .wp-block-cover__inner-container {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: center;
    justify-content: center; }
  h2 {
    margin-bottom: 0 !important; } }


.privacy {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #BDBDBD;
  border-radius: 15px;
  background: #fff;
  @media (min-width: 1100px) {
    padding: 45px; }
  @media (max-width: 1099px) {
    width: calc( 100% - 40px);
    padding: 20px; }
  .wp-block-group__inner-container {
    text-align: left; }
  h2 {
    font-size: 16px; }
  p+h2 {
    border-top: 1px solid #E9E9E9;
    margin-top: 20px;
    padding-top: 20px; } }


.vehicle-header {
  min-height: 200px;
  margin-bottom: 0px;
  box-sizing: border-box;
  background: #fff !important;
  @media (min-width: 1100px) {
    margin-top: 100px; }
  @media (max-width: 1099px) {
    margin-top: 60px; }
  h1 {
    color: #000 !important;
    &:before {
      background-image: url(../image/img34.png)  !important;
      background-repeat: {}
      background-position: {}
      background-size: {} } } }
.shop-name {
  @include shop-name;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    height: 40px;
    margin-bottom: 40px; }
  @media (max-width: 767px) {
    margin-bottom: 20px; }
  ul {
    border-radius: 20px;
    margin-right: 20px; }
  li {
    line-height: 40px;
    &.pref {
      padding-left: 20px;
      padding-right: 20px; }
    &.name {
      padding-left: 20px;
      padding-right: 20px; } }
  .cat {
    height: 40px;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px; } }

.single-vproduct {
  .main-header {
    border-bottom: 1px solid rgba($c3, .5); }
  .wp-block-gallery {
    margin-top: 50px;
    margin-bottom: 50px;
    &:first-of-type {
      ul {
        width: 100%;
        max-width: 820px;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        li {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          &:first-of-type {
            position: relative; } } } } } }

@mixin vehicle_header2-1 {
  margin-bottom: 10px;
  &:before {
    content: '\25CF';
    color: $c1; } }
@mixin vehicle_list1-1 {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: stretch;
  justify-content: flex-start;
  dl {
    text-align: center;
    border: 1px solid #D3D3D3;
    border-right: none;
    @media (min-width: 992px) {
      flex: 1 1 auto;
      &:nth-of-type(9),
      &:last-of-type {
        border-right: 1px solid #D3D3D3; } }
    @media (max-width: 991px) {
      flex: 0 0 33.333%;
      &:nth-of-type(3n),
      &:last-of-type {
        border-right: 1px solid #D3D3D3; } }
    dt {
      background: #e9e9e9; }
    dt,dd {
      font-size: 13px;
      padding: 5px 0; } } }
.vehicle-spec-list {
  text-align: left;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  h2 {
    @include vehicle_header2-1; }

  .content {
    @include vehicle_list1-1; } }



.vehicle-eq-list {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  text-align: left;
  h2 {
    @include vehicle_header2-1; }
  .content {
    @include vehicle_list1-1;

    dl {
      @media (min-width: 992px) {
        flex: 0 1 11.111%;
        &:nth-of-type(9),
        &:last-of-type {
          border-right: 1px solid #D3D3D3; } }
      @media (max-width: 991px) {
        flex: 0 0 33.333%;
        &:nth-of-type(3n),
        &:last-of-type {
          border-right: 1px solid #D3D3D3; } } }
    dd {
      min-height: 40px; }
    .has_eq {
      dd {
        &:before {
          content: '\25CB';
          color: $c1;
          font-size: 16px; } } } } }


.vehicle-prices {
  background: #F7F6E5;
  padding-top: 50px;
  h2 {
    @include header2-2(40px,20px);
    @include header-decoration1; }
  .content {
    background: #fff;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    .rental-car-charge,
    .rental-car-limit {
      @media (min-width: 992px) {
        padding: 40px; }
      @media (max-width: 991px) {
        padding: 10px; } }
    .rental-car-charge + .rental-car-limit {
      padding-top: 0; }
    .car-detail {
      display: none; }
    table {
      width: 100%; }
    .row-img {
      display: none; }
    th,td {
      padding: 10px;
      border: 1px solid #D3D3D3; }
    th {
      background: #D9EBD9;
      width: 200px;
      @media (max-width: 599px) {
        width: 100px; } } }
  .booking-link {
    display: none; }
  .captions {
    max-width: 700px;
    padding: 10px 0;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    p {
      font-size: 12px;
      &:before {
        content: '\25CF';
        color: $c1; } } } }


.vehicle-buttons {
  background: #F7F6E5;
  padding: 40px 0 60px;
  margin-bottom: 0px;
  @media (max-width: 991px) {
    margin-bottom: 50px; }
  a {
    background: $c1;
    display: block;
    padding: 0 20px;
    line-height: 60px;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
    font-size: 16px;
    color: #fff; } }

.vehicle-option-list {
  background-color: #F7F6E5; }
.vehicle-option-list,
.vehicle-discount-list {
  padding-top: 40px;
  padding-bottom: 40px;
  h2 {
    @include header2-2(40px,20px);
    @include header-decoration1; }
  dl {
    width: 100%;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: stretch;
    justify-content: center;
    text-align: left; }
  dt,dd {
    padding: 25px 20px; }
  dt {
    flex: 0 1 auto;
    background: $c1;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border: 1px solid $c1;
    @media (min-width: 768px) {
      width: 200px; }
    @media (max-width: 767px) {
      width: 100%; } }
  dd {
    flex: 0 1 auto;
    width: calc( 100% - 200px );
    border: 1px solid $c1;
    background: #fff;
    line-height: 150%;
    p {
      line-height: 150%; }
    @media (max-width: 767px) {
      width: 100%; } } }
.vehicle-discount-list {
  margin-bottom: 0px; }


.vehicle-gallery {
  max-width: 820px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  @media (min-width: 1100px) {
    margin-bottom: 80px; }
  @media (max-width: 1099px) {
    margin-bottom: 40px; }
  .owl-stage-outer {
    position: relative;
    z-index: 20;
    margin-bottom: 20px; }
  .owl-nav {
    position: absolute;
    z-index: 10;
    top: 390px;
    width: 100%;
    @media (max-width: 991px) {
      display: none; }
    button {
      display: block;
      width: 50px;
      height: 50px;
      border: 2px solid $c1 !important;
      padding: 0;
      border-radius: 25px !important;
      position: absolute;
      span {
        font-size: 20px;
        line-height: 100%; } }
    .owl-prev {
      left: -80px; }
    .owl-next {
      right: -80px; } }
  .item {
    width: 100%;
    background-repeat: no-repeat;
    background-position:  center;
    background-size: cover;
    @media (min-width: 992px) {
      height: 650px; }
    @media (max-width: 991px) {
      height: 350px; } }
  .owl-dots {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: stretch;
    justify-content: center;
    button.owl-dot {
      flex: 1 1 auto;
      display: block;
      background: #f2f2f2;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @media (min-width: 992px) {
        height: 50px; }
      @media (max-width: 991px) {
        flex: 0 0 10vw;
        height: 10vw;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px; }
      &:not( :first-of-type ) {
        margin-left: 10px; }

      span {
        display: none; } } } }

.vproduct-result-list {
  background: #F7F6E5;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom: 80px;
  z-index: 3;
  .content {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: stretch;
    justify-content: center;
    .product {
      text-align: left;
      background: #fff;
      padding: 10px;
      position: relative;
      margin-left: 30px;
      margin-bottom: 30px;
      &:first-of-type {
        margin-left: 0; }
      @media (min-width: 992px) {
        flex: 0 1 22.7%;
        &:nth-of-type(4n+1) {
          margin-left: 0; } }
      @media (min-width: 768px) and (max-width: 991px) {
        flex: 0 1 30.0%;
        &:nth-of-type(3n+1) {
          margin-left: 0; } }
      @media (min-width: 481px) and  (max-width: 767px) {
        flex: 0 1 45.4%;
        &:nth-of-type(2n+1) {
          margin-left: 0; } }
      @media (max-width: 480px) {
        flex: 0 0 100%;
        max-width: calc( 80% - 40px );
        max-width: 260px;
        margin-left: 0px; }
      &>a {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; } }
    .wp-post-image {
      width: calc( 100% + 20px);
      margin-left: -10px;
      margin-top: -10px;
      margin-bottom: 10px; }
    .exception {
      line-height: 150%; }
    h3 {
      border-left: 4px solid $c1;
      padding-left: 5px;
      line-height: 150%;
      margin-bottom: 15px; }
    .campaign {
      h3 {
        color: red;
        border-left: 4px solid red; } }
    .shop-name-small {
      @include shop-name;
      width: auto;
      margin-bottom: 15px;
      justify-content: flex-start;
      display: inline-block;
      ul {
        border-radius: 17px;
        height: 32px;
        margin-right: 5px;
        flex: 1 0 auto;
        margin-bottom: 10px; }
      li {
        line-height: 30px;
        font-size: 12px;
        &.pref {
          padding-left: 10px;
          padding-right: 10px; }
        &.name {
          padding-left: 10px;
          padding-right: 10px; } }
      .cat {
        flex: 0 1 auto;
        width: auto;
        line-height: 28px;
        display: inline-block;
        border-radius: 15px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 12px; } } } }
.search-result {
  z-index: 2;
  margin-bottom: 150px;
  padding-left: 0;
  padding-right: 0;
  .vproduct-result-list {
    padding-top: 80px;
    margin-top: 0; } }


.reserve-calendar {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  #mtsrcb-entry-form {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    max-width: 800px; }
  .rental-vehicle-name {
    color: $c1;
    margin-bottom: 20px;
    @media (min-width: 768px) {
      font-size: 30px; }
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 150%; } }
  .rental-date-box {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 767px) {
      width: 100%;
      display: block; }
    label {
      display: inline-block;
      width: 50px; }
    input[type=text] {
      @include inputbasic1; }
    input.focused {
      background-color: $c4; }
    .date-box-date {
      display: inline-block;
      width: calc(100% - 60px); }
    .date-box-time {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      flex-flow: wrap;
      @media (min-width: 1100px) {
        justify-content: space-between;
        width: calc(100% - 60px); }
      @media (max-width: 1099px) {
        justify-content: center; }
      .form-addchr {
        display: flex;
        align-items: center;
        justify-content: center; } }
    select {
      @include select1;
      padding: 10px;
      background-color: #fff;
      @media (min-width: 1100px) {
        width: 40%; }
      @media (max-width: 1099px) {
        width: 80px; } } }
  #rental-date-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 80px;
    @media (max-width: 767px) {
      transform: rotate(90deg);
      margin-top: 10px;
      margin-bottom: 20px; } }
  .rental-action-row {
    margin-top: 20px;
    flex: 0 0 100%;
    input[type=submit] {
      @include button1; } }
  #mtsrcb-entry-calendar {
    width: 100%; }
  .schedule-calendar table {
    background: #fff;
    .caption-title {
      margin-bottom: 20px;
      font-size: 20px; }
    th,td {
      line-height: 130%; } }
  #alter-day-season.low, td.low-season>.day-number {
    background-color: #bae8ec;
    text-align: center; }
  #alter-day-season.high, td.high-season>.day-number {
    background-color: #fbddf2;
    text-align: center; }
  #change-calendar-pain {
    position: relative;
    z-index: 10; }
  #schedule-calendar-pain {
    position: relative;
    z-index: 5; }
  #calendar-link-prev,
  #calendar-link-next {
    input {
      display: block;
      width: 30px;
      height: 30px;
      font-size: 12px;
      border: 1px solid $c1;
      background: none;
      border-radius: 15px;
      color: $c1; } }
  #calendar-link-prev {
    position: absolute;
    top: 0;
    left: 0; }
  #calendar-link-next {
    position: absolute;
    top: 0;
    right: 0; } }

.schedule-calendar {
  margin-bottom: 40px; }



.form-title-block,
.form-price-block {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  h2 {
    margin-bottom: 10px;
    &:before {
      content: '\25CF';
      color: $c1; } } }
.form-price-block {
  border-collapse: collapse;
  margin-bottom: 50px;
  th,td {
    padding: 10px; } }
#mtsrcb-rental-form,
#mtsrcb-order-list,
#mtsrcb-finish-list,
#mtsrcb-confirm-list,
#mtsrcb-cancel-list {
  text-align: left;
  h3 {
    margin-bottom: 10px;
    &:before {
      content: '\25CF';
      color: $c1; } }
  .mts-form-table {
    margin-left: 0;
    border-collapse: collapse;
    th,td {
      padding: 10px; }
    @media (max-width: 767px) {
      overflow: auto;
      thead,tbody,tr,th,td {
        display: block;
        width: 100%; }
      th.total,
      th.tax,
      th.grand-total {
        text-align: left; } } }
  input[type=text],
  select {
    @include inputbasic1; }
  select {
    @include select1; }
  .action-row {
    text-align: center; }
  input[name=return_entry],
  input[name=return_form],
  input[name=abort_rental],
  input[name=booking_thanks] {
    @include button2;
    margin-left: 10px;
    margin-right: 10px;
    @media (max-width: 767px) {
      margin-bottom: 20px; }
    @media (max-width: 480px) {
      line-height: 40px; } }
  input[name=check_form],
  input[name=booking_rental] {
    @include button1;
    margin-left: 10px;
    margin-right: 10px;
    @media (max-width: 767px) {
      margin-bottom: 20px; } }
  dl {}
  dd {
    margin-left: 0; }
  #rental-agreement-boock {
    text-align: center; } }

#mtsrcb-finish-list {
  &>p {
    text-align: center;
    font-size: 14px;
    margin-bottom: 30px; }
  .action-row {
    input[type=button] {
      @include button3; } }
  .button-primary {
    @include button1;
    margin-right: 10px;
    @media (max-width: 767px) {
      margin-bottom: 20px; } } }
.form-action-block {
  #payjp_checkout_box {
    input[type=button] {
      @include button1;
      margin-left: 10px;
      margin-right: 10px; } }
  #abort-button {
    input[type=submit] {
      @include button2;
      margin-left: 10px;
      margin-right: 10px; } } }
.end-payment {
  margin-bottom: 200px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  #mts-message-pane {
    border: 1px solid #ededed;
    padding: 20px; } }

#payment-notice {
  a {
    color: $lc1; } }

div.blog {
  .home-blog {
    padding-top: 30px;
    .content {
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: 767px) {
        flex-flow: wrap;
        flex-flow: wrap; } }
    .entry {
      &:not( :first-child ) {
        margin-left: 20px; }

      @media (min-width: 768px) {
        flex: 0 1 25%; }
      @media (max-width: 767px) {
        flex: 0 1 50%;
        max-width: calc( 50% - 10px );
        &:nth-of-type(2n+1) {
          margin-left: 0; } } } } }
.noimage {
  width: 100%;
  height: 160px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90% auto;
  background-color: #f5f5f5;
  margin-bottom: 10px; }
.blog-archive {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 767px) {
    flex-flow: wrap; }
  .wp-block-column {
    @media (min-width: 768px) {
      &:nth-of-type(1) {
        flex: 1 1 auto; }
      &:nth-of-type(2) {
        flex: 0 0 180px;
        margin-left: 60px; } }
    @media (max-width: 767px) {
      &:nth-of-type(1) {
        flex: 0 0 100% !important; }
      &:nth-of-type(2) {
        flex: 0 0 100% !important; } } }
  .entry {
    flex: 0 1 33.333%;
    border: 1px solid #ededed; } }


.home-blog {
  margin-bottom: 40px;
  .content {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: stretch;
    justify-content: center; }
  .entry {
    background: #fff;
    padding: 10px;
    text-align: left;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 20px;
    width: 100%;
    &:not( :first-of-type) {
      margin-left: 20px; }
    @media (min-width: 768px) {
      width: 100%;
      max-width: calc( 25% - 20px );
      &:nth-of-type(4n + 1) {
        margin-left: 0; } }
    @media (min-width: 600px) and (max-width: 767px) {
      max-width: calc( 33% - 20px );
      &:nth-of-type(3n + 1) {
        margin-left: 0; } }
    @media (max-width: 599px) {
      flex: 0 1 auto;
      max-width: calc( 50% - 10px );
      &:nth-of-type(2n + 1) {
        margin-left: 0; } }
    position: relative;
    .tag-links {
      a {
        color: #3F9AE6;
        display: inline-block;
        margin-right: 10px; } }
    img.wp-post-image {
     width: calc( 100% + 20px );
     margin-left: -10px;
     margin-top: -10px;
     display: block;
     margin-bottom: 15px; }
    .date {
      color: $c1;
      line-height: 100%;
      margin-bottom: 10px;
      .new {
        display: inline-block;
        margin-left: 10px;
        .the-new {
          background: red;
          color: #fff;
          font-size: 10px;
          padding: 0 5px 1px;
          position: relative;
          top: -2px; } } }
    h3 {
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 10px; } } }

.yearly-list {
  text-align: left;
  li {
    &:before {
      content: '\25CF';
      color: $c1;
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 5px; }
    a {
      color: #232323; } } }


.the_entry {
  text-align: left;
  .date {
    color: $c1;
    margin-bottom: 10px;
    .new {
      display: inline-block;
      margin-left: 10px;
      .the-new {
        background: red;
        color: #fff;
        font-size: 10px;
        padding: 0 5px 1px;
        position: relative;
        top: -2px; } } }

  h1 {
    font-size: 22px;
    margin-bottom: 10px; }
  .tag-links {
    margin-bottom: 20px;
    a {
      color: #3F9AE6;
      display: inline-block;
      margin-right: 10px; } }
  .wp-post-image {
    margin-bottom: 30px; } }


.post-navigation {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: stretch;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  a {
    display: block; }
  .prev,
  .next {
    margin-left: 10px;
    margin-right: 10px;
    a {
      @include button4; } }
  .prev {
    a {
      &:before {
        @include fw;
        content: "\f053";
        font-size: 10px;
        display: inline-block;
        margin-right: 10px;
        line-height: 100%;
        position: relative;
        top: -1px; }
      @media (max-width: 991px) {
        order: 1;
        width: 140px;
        padding: 0;
        line-height: 40px; } } }
  .next {
    a {
      &:after {
        @include fw;
        content: "\f054";
        font-size: 10px;
        display: inline-block;
        margin-left: 10px;
        line-height: 100%;
        position: relative;
        top: -1px; }
      @media (max-width: 991px) {
        order: 2;
        width: 140px;
        padding: 0;
        line-height: 40px; } } }
  .totop {
    @media (max-width: 991px) {
      order: 3;
      margin-top: 20px; }
    a {
      @include button1-no-arrow;
      @media (max-width: 991px) {
        width: 280px;
        line-height: 40px; } } } }



.sp-navigation {
  position: fixed;
  top: 60px;
  left: 0;
  height: 0;
  overflow: hidden;
  width: 100%;
  background: #000;
  transition: all .6s ease 0s;
  &.active {
    height: calc( 100vh - 60px ); }
  .wp-block-group__inner-container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px; }

  li {
    position: relative;
    padding-left: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #fff;
    a {
      color: #fff; } }
  img {
    height: 20px !important;
    width: auto !important;
    position: absolute;
    left: 10px;
    margin-right: 10px; }
  li {
    &:nth-of-type(1) {
      img {
        left: 18px;
        top: 35%; } }
    &:nth-of-type(2) {
      img {
        top: 35%; } }
    &:nth-of-type(3) {
      img {
        left: 22px;
        top: 37%; } }
    &:nth-of-type(4) {
      img {
        left: 19px;
        top: 37%; } } }

  .wp-block-buttons {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;
    align-items: stretch;
    justify-content: center;
    .wp-block-button {
      flex: 0 1 150px;
      margin-left: 10px;
      margin-right: 10px;
      .wp-block-button__link {
        width: 100%;
        padding: 15px;
        border-radius: 10px;
        font-size: 16px; } } } }

@mixin guide-h3 {
  background: $c1;
  color: #fff;
  padding: 12px;
  position: relative;
  @media (min-width: 1100px) {
    width: calc( 100% + 280px );
    margin-left: -140px;
    font-size: 22px;
    margin-bottom: 30px; }
  @media (max-width: 1099px) {
    width: calc( 100% + 40px );
    margin-left: -20px;
    font-size: 18px;
    margin-bottom: 10px; } }

.guide-arrow {
  @media (min-width: 1100px) {
    margin-top: 30px;
    margin-bottom: 30px; }
  @media (max-width: 1099px) {
    margin-top: 20px;
    margin-bottom: 20px; }
  img {
    max-width: 34px !important; } }
.guide-flow {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid $c1;
  position: relative;
  text-align: left;
  @media (max-width: 1099px) {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    width: calc( 100% - 40px ); }
  .wp-block-group__inner-container {
    padding-bottom: 30px;
    @media (min-width: 1100px) {
      padding-left: 140px;
      padding-right: 140px; }
    @media (max-width: 1099px) {
      padding-left: 20px;
      padding-right: 20px; } }
  h3 {
   @include guide-h3;
   img {
     vertical-align: middle;
     position: relative;
     top: -2px;
     margin-right: 10px; }
   &:before {
     content: '**';
     position: absolute;
     display: block;
     top: 0;
     left: 0;
     height: 100%;
     text-align: center;
     background: #656565;
     display: flex;
     align-items: center;
     justify-content: center;
     font-weight: 500;
     font-family: $f1;
     @media (min-width: 1100px) {
       width: 200px; }
     @media (max-width: 1099px) {
       width: 50px; } } }
  .guide-button1 {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: stretch;
    justify-content: center;
    background: #F7F6E5;
    padding: 20px;
    border-radius: 15px;
    margin-top: 40px;
    margin-bottom: 30px;
    .wp-block-button {
      display: block;
      margin-right: 0;
      @media (min-width: 1100px) {
        flex: 0 1 50%;
        max-width: calc( 50% - 10px );
        &:not( :first-of-type ) {
          margin-left: 20px; } }
      @media (max-width: 1099px) {
        flex: 0 1 100%;
        margin-left: 0; }
      .wp-block-button__link {
        min-width: 1px;
        width: 100%;
        max-width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        position: relative;
        &:after {
          @include fw;
          content: '\f054';
          position: absolute;
          right: 25px; } } } }
  hr {
    margin-top: 15px;
    margin-bottom: 15px; }
  p,li {
    a {
      color: $lc1;
      text-decoration: underline; } }
  p.has-background {
    margin-top: 10px;
    padding: 15px;
    line-height: 150%; }
  .guide-ol1 {
    counter-reset: number 0;
    li {
      padding-left: 30px; }
    li::before {
      counter-increment: number 1;
      content: counter(number);
      display: inline-block;
      background: $c1;
      color: #fff;
      width: 20px;
      line-height: 20px;
      border-radius: 10px;
      text-align: center;
      font-size: 10px;
      margin-right: 10px;
      margin-left: -31px; } } }
.guide-flow1 {
  h3::before {
    content: '01'; } }
.guide-flow2 {
  h3::before {
    content: '02'; } }
.guide-flow3 {
  h3::before {
    content: '03'; } }
.guide-flow4 {
  margin-bottom: 80px;
  h3::before {
    content: '04'; } }

.guide-reservation {
  @media (min-width: 1100px) {
    padding: 80px 0; }
  @media (max-width: 1099px) {
    padding: 40px 20px; }
  .wp-block-columns {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 1100px) {
      margin-bottom: 50px; }
    @media (max-width: 767px) {
      margin-bottom: 0px; }
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: stretch;
    justify-content: center;
    .wp-block-column {
      background: #fff;
      border: 1px solid #D3D3D3;
      border-radius: 20px;
      padding: 25px;
      text-align: left;
      @media (min-width: 992px) {
        &:not( :first-of-type) {
          margin-left: 30px; } }
      @media (max-width: 991px) {
        flex: 1 0 100% !important;
        margin-bottom: 30px; }
      h3 {
        font-size: 18px;
        background: $c1;
        line-height: 40px;
        border-radius: 20px;
        color: #fff;
        margin-bottom: 15px; } } }
  .has-background {
    padding: 5px;
    margin-top: 10px; } }

.guide-cancel {
  width: 100%;
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid $c1;
  background: #fff;
  @media (max-width: 1099px) {
    max-width: 900px;
    text-align: left; }
  .wp-block-group__inner-container {
    padding: 0 30px 30px;
    position: relative; }
  h3 {
    width: calc( 100% + 60px );
    margin-left: -30px;
    margin-bottom: 20px;
    background: $c1;
    color: #fff;
    line-height: 40px; }
  p {
    @media (max-width: 1099px) {
      width: calc( 100% - 120px);
      margin-left: 0; } }
  .icon-cancel {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 20px;
    font-size: 18px;
    margin-bottom: 20px;
    @media (min-width: 1100px) {
      right: 50px; }
    @media (max-width: 1099px) {
      right: 20px; } }
  table {
    margin-top: 20px;
    th,td {
      padding: 5px;
      text-align: center; }
    thead {
      th {
        background: #E9E9E9;
        &:first-of-type {
          border-right: 1px solid #fff; } } }
    tbody {
      td {
        border-bottom: 1px solid #e9e9e9;
        &:first-of-type {
          border-right: 1px solid #e9e9e9; } } } } }

.guide-compensation {
  @media (min-width: 1100px) {
    padding: 80px 0; }
  @media (max-width: 1099px) {
    padding: 40px 0; } }
.guide-insurance,
.guide-noc {
  border: 1px solid $c1;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 30px;
  padding-left: 0;
  padding-right: 0;
  background: #fff;
  @media (max-width: 1099px) {
    margin-left: auto;
    margin-right: auto;
    max-width: calc( 100% - 40px ); }
  .wp-block-group__inner-container {
    text-align: left;
    @media (min-width: 1100px) {
      padding-left: 140px;
      padding-right: 140px; }
    @media (max-width: 1099px) {
      padding-left: 20px;
      padding-right: 20px; } }
  h3 {
    @include guide-h3; }
  h4 {
    font-size: 18px; }
  p {
    margin-bottom: 20px;
    line-height: 180%; }
  table {
    margin-bottom: 20px;
    background: #fff;
    th,td {
      text-align: center;
      border: 1px solid #D3D3D3;
      padding: 5px; }
    thead {
      th {
        background: #e5e5e5; } } }
  .has-background {
    margin-bottom: 20px;
    padding: 10px 20px; } }

#mtsrcb-find-entry {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  .reserve-box {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: center;
    justify-content: flex-start;
    label {
      display: block;
      flex: 0 0 150px;
      text-align: left;
      @media (max-width: 767px) {
        flex: 0 0 100%; } }
    input {
      padding: 10px;
      flex: 0 1 auto;
      font-size: 15px;
      @media (max-width: 767px) {
        flex: 0 0 100%; } }
    .button-primary {
      @include button1;
      margin-left: auto;
      margin-right: auto; } }
  #reserve-action-box {
    margin-top: 40px;
    text-align: center;
    margin-bottom: 150px; }
  .reserve-description {
    margin-bottom: 20px; } }
#mtsrcb-confirm-list,
#mtsrcb-cancel-list {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  .button-primary[name=reserve_payment] {
    @include button1; }
  .button-primary[name=reserve_quit] {
    @include button1; }
  .button-primary[name=cancel_form] {
    @include button3; }
  .button-primary[name=reserve_cancel] {
    @include button2; }
  .button-primary[name=reserve_quit] {
    @include button1;
    @media (max-width: 1099px) {
      margin-bottom: 20px; } } }



.group-agreement {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #BDBDBD;
  border-radius: 15px;
  background: #fff;
  @media (min-width: 1100px) {
    padding: 45px; }
  @media (max-width: 1099px) {
    width: calc( 100% - 40px);
    padding: 20px; }
  .wp-block-group__inner-container {
    text-align: left; }
  h1 {
    font-size: 24px; }
  h2 {
    font-size: 20px; }
  h3 {
    font-size: 18px; }
  p {
    padding-left: 27px;
    text-indent: -28px; }
  p+h2 {
    margin-top: 60px; }
  p+h3 {
    margin-top: 30px; }
  ul {
    padding-left: 27px;
    li {
      font-size: 14px; } }
  p.caption {
    margin-left: 27px; }
  .wp-block-group {
    padding-left: 27px;
    padding-bottom: 30px;
    .wp-block-group__inner-container {} }
  .indent1 {
    p {
      padding-left: 40px;
      text-indent: -42px; } }
  p.date {
    margin-top: 30px;
    padding-left: 0;
    text-indent: 0; } }


.wp-block-table {
  @media (max-width: 667px) {
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    // &::-webkit-scrollbar
    //  -webkit-appearance: none
    //  width: 7px
    // &::-webkit-scrollbar:vertical
    //  width: 12px
    // &::-webkit-scrollbar:horizontal
    //  height: 12px
    // &::-webkit-scrollbar-thumb
    //  background-color: rgba(0, 0, 0, .5)
    //  border-radius: 10px
    //  border: 2px solid #ffffff
    //  box-shadow: 0 0 1px rgba(255,255,255,.5)
    // &::-webkit-scrollbar-track
    //  border-radius: 10px
 } }    //  background-color: #ffffff


.caption-list1 {
  padding-left: 20px;
  li {
    text-indent: -18px; } }

.lt {
  @media (max-width: 992px) {
    display: none; } }
.sp {
  @media (min-width: 991px) {
    display: none; } }

.ignore-br-lt {
  br {
    @media (min-width: 992px) {
      display: none; } } }
.ignore-br-sp {
  br {
    @media (max-width: 991px) {
      display: none; } } }


.pagination {
  font-size: 14px;
  i {
    font-style: normal;
    display: none; }

  .prev {
    &:before {
      @include fw;
      content: '\f053'; } }
  .next {
    &:before {
      @include fw;
      content: '\f054'; } }
  &>span,a {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px; }
  &>span {
    color: $c1;
    font-size: 18px;
    font-weight: bold; } }


.price-attention {
  &:empty {
    display: none; }
  width: calc( 100% - 40px );
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  padding-bottom: 1px;
  text-align: left;
  p,li,th,td {
    font-size: 14px;
    line-height: 170%; }
  p,ul,table {
    margin-bottom: 10px; }
  ul {
    padding-left: 15px;
    li {
      text-indent: -15px; } }
  table {
    // @media (min-width: 768px)
    th,td {
      border-bottom: 1px solid #ccc;
      padding: 5px 0; }
    @media (max-width: 599px) {
      th,td {
        padding: 5px 10px 5px 0; }
      width: 100%;
      display: block;
      overflow-x: scroll;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch; } } }


#month-calendar {
  .schedule-calendar {
    .color-caption {
      text-align: left;
      p {
        font-size: 13px;
        color: #666; }
      span {
        display: inline-block;
        margin-right: 20px; }
      .high {
        &:before {
          content: '';
          display: inline-block;
          width: 40px;
          background: #fbddf2;
          height: 14px;
          vertical-align: middle;
          margin-right: 10px; } }
      .low {
        &:before {
          content: '';
          display: inline-block;
          width: 40px;
          background: #bae8ec;
          height: 14px;
          vertical-align: middle;
          margin-right: 10px; } } } } }

.anchor {
  position: relative;
  top:  -50px; }

