.vision {
  margin-bottom: 200px;

  @media (max-width: 1099px) {
    padding-left: 40px;
    padding-right: 40px; }
  @media (max-width: 767px) {
    margin-bottom: 70px; }

  .wp-block-group__inner-container {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    p {
      font-size: 14px;
      line-height: 200%;
      font-weight: 700;
      text-shadow: 0 0 10px #fff, 0 0 5px #fff; } } }



.footer {
  position: relative;
  z-index:  1;
  @media (min-width: 1100px) {
    background: url( ../image/img35.jpg) no-repeat center bottom / cover;
    margin-top: 100px;
    padding-top: 300px;
    padding-bottom: 100px; }
  @media (max-width: 1099px) {
    background: url( ../image/img35.jpg) no-repeat center bottom / cover;
    margin-top: 100px;
    padding-top: 200px;
    padding-bottom: 30px; }
  @media (max-width: 667px) {
    background: url( ../image/img35.jpg) no-repeat center bottom / 667px auto;
    margin-top: 50px;
    padding-top: 100px;
    padding-bottom: 10px; }
  &:before {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    content: '';
    display: block;
    z-index: 2; }
  &:after {
    background: #000;
    background: linear-gradient(180deg, rgba(#000,0) 0%, rgba(#000,.4) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    content: '';
    display: block;
    z-index: 2; }
  .ftl {
    position: relative;
    z-index: 5;
    img {
      width: 210px; }
    @media (max-width: 1099px) {
      // display: none
      img {
        width: 180px;
        margin-left: 25px; } } }
  .sns-nav {
    position: relative;
    z-index: 5;
    margin-bottom: 20px;
    display: none;
    @media (max-width: 991px) {
      display: none; }
    img {
      margin-left: 15px;
      margin-right: 15px;
      height: 25px;
      width: auto !important; } }

  .footer-nav {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 5;
    li {
      padding-right: 15px;
      padding-left: 15px;
      border-right: 1px solid #fff;
      line-height: 140%;
      &:last-child {
        border-right: none; }
      @media (max-width: 767px) {
        margin-bottom: 10px;
        &:nth-last-of-type(2) {
          border-right: none; }
        &:last-of-type {
          flex: 0 0 100%; } } }
    a {
      color: #fff; } }
  .link-banner {
    @media (min-width: 768px) {
      padding-top: 20px; }
    z-index: 5;
    position: relative;
    a {
      color: #fff; } }
  .rights {
    position: relative;
    z-index: 5;
    color: #fff;
    @media (min-width: 768px) {
      padding-top: 20px; }
    @media (max-width: 767px) {
      padding-top: 10px; } } }
.home-footer {
  margin-top: -100px;
  padding-top: 180px;
  background: url( ../image/img72.jpg) no-repeat center bottom / cover;
  z-index: 1;
  @media (max-width: 1099px) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 200px; }
  &:before {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    content: '';
    display: block;
    z-index: 2; }
  .footer {
    background: none;
    padding-top: 0;
    &:before {
      display: none; } } }

.gototop {
  width: 90%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 10;
  height: 0;
  .wp-block-button {
    margin-bottom: 0;
    .wp-block-button__link {
      border: 1px solid #fff;
      background: #000;
      border-radius: 30px;
      padding: 0;
      position: absolute;
      @media (min-width: 768px) {
        min-width: 60px;
        width: 60px;
        height: 60px;
        right: 0;
        top: -100px; }
      @media (max-width: 767px) {
        min-width: 40px;
        width: 40px;
        height: 40px;
        right: 0;
        top: -100px; }

      &:before {
        @include fw;
        content: '\f077';
        display: block;
        position: relative;
        @media (min-width: 768px) {
          font-size: 15px;
          top: 22px; }
        @media (max-width: 767px) {
          font-size: 12px;
          top: 13px; } } } } }

