$c1: #007800;
$c2: #232323;
$c3: #B4A500;
$c4: #F4F1D9;
$c5: #D33A26;

$lc1: #299CD3;

$f1: 'Oswald', sans-serif;
$f2:  'Noto Sans JP', sans-serif;

@mixin fw {
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  position: relative; }

@mixin area-list {
  color: $lc1;
  padding-left: 20px;
  display: block;
  line-height: 150%;
  &::before {
    @include fw;
    content:  "\f054";
    font-size: 12px;
    margin-right: 5px;
    top: -1px;
    margin-left: -13px;
    display: inline-block;
    position: relative; } }

@mixin header2-2($mb1:40px,$mb2:20px) {
  color: $c1;
  @media (min-width: 1100px) {
    margin-bottom: $mb1;
    font-size: 26px; }
  @media (max-width: 1099px) {
    margin-bottom: $mb2;
    font-size: 22px;
    line-height: 150%; }
  @content; }
@mixin header-decoration1 {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  &:before {
    background: url(../image/img43.png) no-repeat left center / contain;
    content: '';
    display: inline-block;
    vertical-align: middle; }
  &:after {
    background: url(../image/img44.png) no-repeat left center / contain;
    content: '';
    display: inline-block;
    vertical-align: middle; }
  @media (min-width: 992px) {
    &:before {
      width: 78px;
      height: 12px;
      margin-right: 30px; }
    &:after {
      width: 78px;
      height: 12px;
      margin-left: 30px; } }
  @media (min-width: 481px) and (max-width: 991px) {
    &:before {
      width: 50px;
      height: 12px;
      margin-right: 30px; }
    &:after {
      width: 50px;
      height: 12px;
      margin-left: 30px; } }
  @media (max-width: 480px) {
    padding-left: 60px;
    padding-right: 60px;
    &:before {
      width: 50px;
      height: 12px;
      position: absolute;
      left: 15px;
      top: 45%; }
    &:after {
      width: 50px;
      height: 12px;
      position: absolute;
      right: 15px;
      top: 45%; } } }

@mixin header-decoration2 {
  &:before {
    background: url(../image/img48.png) no-repeat left center / contain;
    content: '';
    display: inline-block;
    vertical-align: middle; }
  &:after {
    background: url(../image/img49.png) no-repeat left center / contain;
    content: '';
    display: inline-block;
    vertical-align: middle; }
  @media (min-width: 992px) {
    &:before {
      width: 78px;
      height: 12px;
      margin-right: 30px; }
    &:after {
      width: 78px;
      height: 12px;
      margin-left: 30px; } }
  @media (min-width: 481px) and (max-width: 991px) {
    &:before {
      width: 50px;
      height: 12px;
      margin-right: 30px; }
    &:after {
      width: 50px;
      height: 12px;
      margin-left: 30px; } }
  @media (max-width: 480px) {
    padding-left: 60px;
    padding-right: 60px;
    &:before {
      width: 50px;
      height: 12px;
      position: absolute;
      left: 5px;
      top: 45%; }
    &:after {
      width: 50px;
      height: 12px;
      position: absolute;
      right: 5px;
      top: 45%; } } }

@mixin button1($w:240px ,$mw:false) {
  background: $c1;
  color: #fff;
  border: none;
  width: $w;
  -webkit-appearance: none;
  @if($mw) {
    max-width: $mw; }
  line-height: 60px;
  border-radius: 30px;
  font-size: 16px;
  background-image: url( ../image/img42.png );
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 5px 8px;
  cursor: pointer;
  padding: 0; }


@mixin button1-no-arrow {
  background: $c1;
  color: #fff;
  border: none;
  width: 240px;
  line-height: 60px;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  padding: 0; }

@mixin button2 {
  background:  #ccc;
  color: #000;
  border: none;
  line-height: 60px;
  border-radius: 30px;
  font-size: 16px;
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 5px 8px;
  cursor: pointer;
  padding: 0 40px;
  -webkit-appearance: none; }

@mixin button3 {
  background:  $c2;
  color: #fff;
  border: none;
  line-height: 60px;
  border-radius: 30px;
  font-size: 16px;
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 5px 8px;
  cursor: pointer;
  padding: 0 40px;
  -webkit-appearance: none; }

@mixin button4 {
  background: transparent;
  color: #2b2b2b;
  border: none;
  line-height: 60px;
  border-radius: 30px;
  font-size: 16px;
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 5px 8px;
  cursor: pointer;
  padding: 0 40px;
  -webkit-appearance: none; }

@mixin shop-name {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: stretch;
  justify-content: center;
  ul {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: stretch;
    justify-content: flex-start;
    border: 1px solid $c1;
    overflow: hidden;
    @media (max-width: 767px) {
      margin-bottom: 10px; }
    li {
      display: flex;
      flex-direction: row;
      flex-flow: wrap;
      align-items: stretch;
      justify-content: center;
      &.pref {
        background: $c1;
        color: #fff; }
      &.name {
        color: $c1; } } }
  .cat {
    border: 1px solid $c1;
    color: $c1;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    margin-right: 0; } }


iframe {
  box-sizing: border-box; }

@mixin inputbasic1 {
  border: 1px solid #bdbdbd;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  background: #fff; }
@mixin select1 {
  border-radius: 0px;
  background-image: url( ../image/img41.png );
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 8px auto; }


.has-tabi-green-color {
  color: $c1; }
.has-tabi-black-color {
  color: $c2; }
.has-tabi-yellow-color {
  color: $c3; }
.has-tabi-white-color {
  color: $c4; }
.has-tabi-red-color {
  color: $c5; }
.has-tabi-bg-green-color {
  color: #E5F1E5; }
.has-tabi-bg-yellow-color {
  color: #F7F6E5; }


.has-tabi-green-background-color {
  background-color: $c1; }
.has-tabi-black-background-color {
  background-color: $c2; }
.has-tabi-yellow-background-color {
  background-color: $c3; }
.has-tabi-white-background-color {
  background-color: $c4; }
.has-tabi-red-background-color {
  background-color: $c5; }
.has-tabi-bg-green-background-color {
  background-color: #E5F1E5; }
.has-tabi-bg-yellow-background-color {
  background-color: #F7F6E5; }
